import logo from './logo.svg';
import './App.css';
import {Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import Navigation from './Navigation';
import Head from './Head';

function App() {
  return (
    <>
   <Navigation /> 
   <Head />



</>
  );
}

export default App;
