import { React, useEffect, useState, useContext } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Button, Nav, NavDropdown, Form, FormControl, Row, Col, Card, CardGroup } from 'react-bootstrap';
import { Navbar, Figure } from 'react-bootstrap';
import contract from "./blockchain/Contract";
import { ethers } from "ethers";
import './App.css';

import banner from './images/banner1.png';


const Head = props => {
  const [count, setCount] = useState(0);
  const imagePlus = () => {
    if (count < 2) {
      setCount(count + 1);
    }
  };

  const imageMinus = () => {
    if (count <= 0) {
      setCount(0);
    } else {
      setCount(count - 1);
    }
  };

  const imageMax = () => {
    setCount(2);
  };

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
      return 'iOS';

    }
    else if (userAgent.match(/Android/i)) {

      return 'Android';
    }
    else {
      return 'unknown';
    }
  }


  const mintHandler = async () => {
    try {
      if (!window.ethereum) {
        if (getMobileOperatingSystem() === "iOS" || getMobileOperatingSystem() === "Android") {
          window.location.replace("https://metamask.app.link/dapp/nftvampire.club/");
        }
        return alert("Please install Metamask");
      }
      // Request Metamask for accounts
      await window.ethereum.request({ method: "eth_requestAccounts" });

      // Set Current Signer
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      console.log(provider);
      const signer = provider.getSigner();
      console.log(signer);
      const address = await signer.getAddress();
      console.log(address);
      mint({ signer, address });
    } catch (error) {
      alert(error);
      throw new Error("No Ethereum Object");
    }
  };

  const getEthereumContract = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const nftContract = new ethers.Contract(
      contract.address,
      contract.abi,
      provider
    );
    return nftContract;
  };

  const mint = async (props) => {
    // Get Ethereum Contract
    const nftContract = await getEthereumContract(props.signer);
    const nftContractWithSigner = nftContract.connect(props.signer);

    let tx;
    // console.log(props.address);
    try {
      tx = await nftContractWithSigner.mint(
        props.address,
        count,
      );
      await tx.wait();
    } catch (error) {
      if (error.code == "UNPREDICTABLE_GAS_LIMIT") {
        alert("Only Owner can mint");
      } else if (error.code == 4001) {
        alert("Transaction Declined!");
      }
      console.log(error.code);
    }
  };

  return (
    <>

      <div style={{ backgroundColor: 'black', backgroundSize: 'fixed', margin: '0%', padding: '10%', paddingBottom: '20%', width: '100%', height: '300%' }}>

        <Card className="text-center" style={{ backgroundColor: "transparent" }}>
          <Card.Body>
            <Card.Title><p style={{ textAlign: 'center', color: 'white', fontSize: '25sp', paddingTop: '5%' }}>2 free + gas mint per wallet</p></Card.Title>
            <h1 style={{ color: 'white', textAlign: 'center' }}>Minting Page</h1>
            <p style={{ textAlign: 'center', color: 'white', fontSize: '25sp' }}>The Dracula Vampire Club is a collection of 10,001 unique Dracula NFTs, unique digital collectibles that live on the Ethereum blockchain.</p>

            <Card.Text>
              <div style={{ display: 'flex', backgroundColor: 'rgb(10 ,10, 10)', borderRadius: '20px', marginLeft: '15%', marginRight: '15%' }}>

                <Button style={{ backgroundColor: 'transparent', border: 'none', margin: '1%' }} onClick={() => imageMinus()}><span >-</span></Button>
                <span style={{ backgroundColor: 'transparent', border: 'none', margin: '1%', color: 'white', fontWeight: 'bold', fontSize: '1.5em' }}>{count}</span>
                <Button style={{ backgroundColor: 'transparent', border: 'none', margin: '1%' }} onClick={() => imagePlus()}><span>+</span></Button>
                <Button style={{ backgroundColor: 'transparent', border: '1px solid white', margin: '0.5%', position: 'absolute', right: '20%', fontSize: '1em', border: 'none', fontWeight: 'bolder' }} onClick={() => imageMax()}><span >SET MAX</span></Button>
              </div>
            </Card.Text>
            <Button variant="success" style={{ backgroundColor: 'rgb(210 ,255, 92)', color: 'black', }} onClick={mintHandler}>Mint Now</Button>
          </Card.Body>

        </Card>

      </div>
    </>
  );
};

export default Head