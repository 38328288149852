import { React } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Button, Nav, NavDropdown, Form, FormControl } from 'react-bootstrap';
import { Navbar, Figure } from 'react-bootstrap';
import './App.css';
import './fonts/Montserrat-Medium.otf';
import logo from './images/logo.png';
import { Link } from "react-scroll";








const Navigation = props => {

  const twitter = () => {
    window.open("https://twitter.com/nftvampireclub", "_blank");
  };

  const discord = () => {
    window.open("http://discord.gg/nftvampireclub", "_blank");
  };

  const opensea = () => {
    window.open("https://opensea.io/collection/dracula-vampire-club", "_blank");
  };


  return (
    <>

      <Navbar style={{ backgroundColor: 'rgb(10 ,10, 10)' }} expand="lg" >
        <Container fluid>
          <Navbar.Brand href="#">
            <img
              style={{ width: '60%', height: '60%', marginLeft: '20%' }}
              src={logo}
              className='img-fluid'
              alt='Your Logo Here'

            /></Navbar.Brand>

          <Navbar.Toggle aria-controls="navbarScroll" style={{ background: 'white' }} />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: '100px' }}
              navbarScroll
            >

            </Nav>
            <Nav style={{ paddingRight: '15%' }}>

              <Link offset={-3000} style={{ fontFamily: 'Montserrat-Medium', fontSize: '1.2em', color: 'white', marginRight: '20%', letterSpacing: '0.1em', }}>Mint</Link>
              <Link style={{ fontFamily: 'Montserrat-Medium', fontSize: '1.2em', color: 'white', marginRight: '20%', letterSpacing: '0.1em', }}>
                <Button target="_blank" style={{ marginRight: '0%', color: 'white', textDecoration: 'none' }} onClick={() => twitter()}>Twitter</Button>

              </Link>

              <Link style={{ fontFamily: 'Montserrat-Medium', fontSize: '1.2em', color: 'white', marginRight: '20%', letterSpacing: '0.1em', }}>
                <Button target="_blank" style={{ marginRight: '0%', color: 'white', textDecoration: 'none' }} onClick={() => discord()}>Discord</Button>
              </Link>

            </Nav>

            <Button style={{ backgroundColor: 'black', fontFamily: 'Montserrat-Medium', letterSpacing: '0.1em', border: '1px solid white', borderRadius: '20px' }} >
              <Button target="_blank" style={{ marginRight: '0%', color: 'white', textDecoration: 'none' }} onClick={() => opensea()}>Open Sea</Button>
            </Button>

          </Navbar.Collapse>
        </Container>
      </Navbar>


    </>
  );
};

export default Navigation